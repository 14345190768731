<template>
  <canvas
    ref="canvas"
    class="chart"
    :height="height"
    :width="width"
  />
</template>

<script>
import Chart from 'chart.js'
import { mapState } from 'vuex'
import humanNumber from 'human-number'

export default {
  name: 'ImpactChart',
  props: {
    years: {
      type: Array,
      default: () => []
    },
    height: {
      type: Number,
      default: 250
    },
    width: {
      type: Number,
      default: 350
    },
    legendPosition: {
      type: String,
      default: 'top'
    },
    showTitle: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(['colors']),
    transposedYearData () {
      if (!this.years || !this.years.length) return null
      const lookup = {
        materials: 'Carbon Source (embodied carbon)',
        plants: 'Carbon Sequestered (carbon sinks)',
        operations: 'Carbon Costs (operational carbon)',
        overall: 'Carbon Sequestered Beyond Emissions'
      }
      const data = []
      for (const key in lookup) {
        data.push({
          label: lookup[key],
          data: this.years.map(year => {
            let val = Math.round(year[key] * 10) / 10
            if (key === 'plants') {
              val = -val
            }
            if (key === 'overall') {
              val = Math.max(-val, 0)
            }
            return val
          })
        })
      }
      return data
    }
  },
  watch: {
    transposedYearData: {
      immediate: true,
      handler (val) {
        if (val) {
          setTimeout(() => {
            const canvas = this.$refs.canvas
            const ctx = canvas.getContext('2d')
            const now = new Date()
            const currYear = now.getFullYear()
            const years = new Array(40).fill(null).map((x, i) => i + 1 + currYear)
            const colors = [
              this.colors.crimson,
              this.colors.shamrock,
              this.colors.warning,
              this.colors.north
            ]
            this.chart = new Chart(ctx, {
              type: 'line',
              data: {
                labels: years,
                datasets: this.transposedYearData.map((set, i) => ({
                  ...set,
                  borderColor: colors[i],
                  backgroundColor: colors[i],
                  fill: false
                }))
              },
              options: {
                maintainAspectRatio: false,
                title: {
                  display: this.showTitle,
                  text: 'Carbon impact (metric tonnes)',
                  position: 'left',
                  fontStyle: 'regular',
                  fontSize: 20
                },
                legend: {
                  position: this.legendPosition
                },
                elements: {
                  point: {
                    radius: 0
                  }
                },
                scales: {
                  yAxes: [{
                    ticks: {
                      callback: (value, index, values) => {
                        return humanNumber(value) + ' mt'
                      }
                    }
                  }]
                },
                tooltips: {
                  callbacks: {
                    label (tooltipItem, data) {
                      let label = data.datasets[tooltipItem.datasetIndex].label || ''
                      if (label) {
                        label += ': '
                      }
                      label += `${tooltipItem.yLabel} metric tonnes`
                      return label
                    }
                  }
                }
              }
            })
          }, 300)
        }
      }
    }
  }
}
</script>

<style>

</style>
